import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import IndividualCaseStudy from "../../screens/resources/case-study/slideshow"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <IndividualCaseStudy
        title="Case Study #1: Hannah"
        src="https://media.visbymedical.com/case-study/sti-female-patient-hannah/story.html"
        bodytxt="A case of overtreatment associated with syndromic management of STIs."
      />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
	title="Patient Case Study 1: Overtreatment of STIs"
	description="Hannah, an 18-year-old female college freshman, presents to a local clinic after experiencing a week of vaginal discharge and itching."
	image="/meta/case-study-hannah.jpg"
  />
)
